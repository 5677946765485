/*

 Custom styles for Khana Capital organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

.header-provider--logo {
    max-width: 63px !important;
}
.header--logo {
    max-width: 63px !important;
}

.login--logo {
    max-width: 85px !important;
}
.login--form-logo-image {
    width: 159px !important;
}

.user-profile__items .personal-data__items--title {
    color: $organization_program_default !important;
    border-color: $organization_program_default !important;
}

.carousel--color .carousel--item {
    position: relative;

    &::before {
        content: "";
        background-color: rgba($color: #000000, $alpha: 0.3);
        height: 100%;
        width: 100%;
        position: absolute;
    }
}