/*

 Custom colors for Khana Capital organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #fcb036;
$organization_primary_focus: #fda81f;
$organization_primary_disabled: #fcb0364d;

$organization_program_default: #3b215e;

$organization_secondary: #3b215e;
$organization_secondary_focus: #3b215ee7;

$organization_secondary_transparent: #3b215ee7;

$organization_secundary_border: #75618f;

$organization_secundary_link: #ffffff;
$organization_secundary_link_hover: #fcb036;

$organization_secundary_button: #fcb036;
$organization_secundary_button_focus: #fcb036e7;
