@import '../../../media-query';
@import '../../../styles/constants/colors.scss';
@import '../../../styles/constants/fonts.scss';
.container__invoices--header {
    .sortable-column__items {
        display: flex;
        justify-content: flex-start;
        // width: 200px;
        &--text {
            font-size: 11px;
            font-weight: bold;
            font-family: Frutiger;
            color: $cool_blue;
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &--icon {
            display: flex;
            align-items: center;
            margin-left: 10px;
        }
    }
    .sortable-column__items:first-child {
        width: 9%;
    }
    .sortable-column__items:nth-child(2) {
        width: 11%;
    }
    .sortable-column__items:nth-child(3) {
        width: 10%;
    }
    .sortable-column__items:nth-child(4) {
        width: 12%;
    }
    .sortable-column__items:nth-child(5) {
        width: 17%;
    }
    .sortable-column__items:nth-child(6) {
        width: 13%;
    }
    .sortable-column__items:nth-child(7) {
        width: 17%;
    }

    .selectable-column__items {
        width: 12%;
        justify-content: flex-end;
        white-space: nowrap;
    }
}

// ============ TABELA 2 ============
.container__invoices--header-second {
    .sortable-column__items {
        display: flex;
        justify-content: flex-start;
        // width: 200px;
        &--text {
            font-size: 11px;
            font-weight: bold;
            font-family: Frutiger;
            color: $cool_blue;
            display: flex;
            align-items: center;
        }
        &--icon {
            display: flex;
            align-items: center;
            margin-left: 10px;
        }
    }
    .sortable-column__items:first-child {
        width: 11%;
    }
    .sortable-column__items:nth-child(2) {
        width: 12%;
    }
    .sortable-column__items:nth-child(3) {
        width: 10%;
    }
    .sortable-column__items:nth-child(4) {
        width: 14%;
    }
    .sortable-column__items:nth-child(5) {
        width: 18%;
    }
    .sortable-column__items:nth-child(6) {
        width: 16%;
    }
    .sortable-column__items:nth-child(7) {
        width: 18%;
    }
}
