@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';
@import '../../../../../styles/constants/fonts.scss';
.container__invoices--subtitle {
}

.container__invoices--header,
.container__invoices--header-second {
    height: 40px;
    border-bottom: solid 2px $cool_blue;
    display: flex;
    flex-direction: row;
    padding: 0 28px 0 24px;
    margin-bottom: 12px;
    @include responsive(m) {
        padding: 0 17px 0 24px;
    }
    @include responsive(sm) {
        padding: 0 23px 0 24px;
    }
    .sortable-column__items--text {
        @include responsive(sm) {
            font-size: 0.625rem;
            white-space: nowrap;
        }
    }
    .sortable-column__items--icon {
        @include responsive(sm) {
            margin-left: 3px;
        }
    }
    .selectable-column__items {
        .selectable-column__items--text {
            @include responsive(sm) {
                white-space: nowrap;
            }
        }
    }
}
