@import '../../media-query';
@import '../../styles/constants/colors.scss';

.container__header-provider {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    background: $organization_secondary;
    z-index: 10;
    overflow: hidden;

    .header-provider {
        display: flex;
        justify-content: flex-start;
        height: 70px;
        font-family: 'Frutiger';

        &--logo {
            content: url('../../images/custom_organization/white_logo.svg');
            display: flex;
            align-items: center;
            max-width: 150px;
        }

        &--menu-itens {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-around;
        }

        &--link {
            display: inline-block;
            color: $organization_secundary_link;
            font-size: 0.875rem;
            font-family: 'Frutiger';
            cursor: pointer;
            text-align: center;
            padding: 0 5px;
            text-decoration: none;
        }

        &--link:hover {
            color: $organization_secundary_link_hover;
            transition: 0.2s;
        }

        &--company {
            border-left: solid 1px $organization_secundary_border;
            border-right: solid 1px $organization_secundary_border;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .company--buttom {
                background: $organization_secundary_button;
                width: 134px;
                height: 19px;
                border: none;
                border-radius: 4px;
                font-family: 'Frutiger';
                font-weight: 500;
                font-size: 0.563rem;
                letter-spacing: 1.5px;
                color: white;
            }

            .company--buttom:hover {
                background: $organization_secundary_button_focus;
            }

            .gfLNMF {
                height: 18px;
                width: 18px;
                .iepmoa {
                    height: 9px;
                    width: 9px;
                    background-color: $white;
                }
            }
        }

        &--profile {
            border-right: solid 1px $organization_secundary_border;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 30px;
        }

        &--company-text {
            font-family: 'Frutiger';
            color: #fafafa;
            font-size: 0.75rem;
            white-space: nowrap;
            overflow: hidden;
            max-width: 80%;
            text-overflow: ellipsis;
        }
    }
}
